<template>
  <div class="viewContainer csv">
    <h1>Bestand updaten</h1>
    <StepNavigation :step="currentStep" :stepTitles="stepTitles" />
    <div class="step0">
      <b-row v-if="currentStep === 0">
        <b-col>
          <h2 class="v2">Datei auswählen</h2>
          <input ref="fileInput" type="file" @change="readFile" class="d-none" />
          <button class="btn btn-primary" @click="$refs.fileInput.click()">Datei auswählen</button>
          <b-row v-if="fileName.length > 0 && csvColumns.length > 0">
            <b-alert class="updater" fade variant="success" :show=showAlert>
              Datei "{{ fileName }}" erfolgreich hochgeladen, {{ csvInput.length }} Zeilen gefunden.
            </b-alert>
          </b-row>
          <ButtonFooter :backButtonVisible="false" :okButtonDisabled="fileName.length === 0 || csvColumns.length === 0"
            :okButtonLabel="this.$t('general.buttons.forward')" :okAction="() => { currentStep += 1 }" />
        </b-col>
      </b-row>
    </div>
    <div class="step1">

      <b-row v-if="currentStep === 1">
        <b-col>
          <h2 class="v2">{{this.$t('updater.step1')}}</h2>
          <TargetMapping :csvColumns="csvColumns" :wineSupplierNotFound="wineSupplierNotFound"
        :finishedMapping="finishedTargetMapping" :rowMappingObjectStart="rowMappingObject" @backFromStep1="handleBackFromStep1" />
          
        </b-col>
      </b-row>
    </div>
    <div v-if="csvColumns.length > 0">
      <div class="step2">

      
      <IdentifierMapping v-if="currentStep === 2" :csvColumns="csvColumns" :finishedMapping="finishedIdentifierMapping"
        :identifierMappingObject="identifierMappingObject" :preSelectedOption="selectedIdentifierOption" @backFromStep2="handleBackFromStep2" />
      </div>

      <b-row class="mt-3" v-if="currentStep === 3">
        <b-col>
          <div v-if="isImporting">
            <h2>Die Liste wird überprüft...</h2>
          </div>
          <div v-else>
            <h2>Überprüfung der Liste abgeschlossen.</h2>
            
            <div class=" mt-4 mb-4">
            <b-alert variant="warning" :show="true" class="updater"> {{ notFoundWines.length }} Weine wurden nicht gefunden</b-alert>
            <b-alert :show="true" class="updater"> {{ foundWinesCount }} Weine wurden gefunden</b-alert>
          </div>
          <b-row v-if="matchingWines.length > 0" class="">
            <b-col>
              <h4>
                Gefundene Weine
              </h4>
              <b-row>
              <b-col> <h3>CSV Wein</h3></b-col><b-col cols="1">  </b-col><b-col><h3>Wein aus Datenbank</h3></b-col>
            </b-row>
              <b-row v-for="(wine,index) in matchingWines" :key="index" >
                <b-col>{{ wine.vineFromSheet }}</b-col><b-col cols="1"> ------- > </b-col><b-col>{{ wine.vineFromVenue }}</b-col><b-col cols="1"><button  v-if="wine.fromSearch" :class="excludeRowsFromUpdate.indexOf(wine.row, wine.csvWine) > -1 ? 'inlineRow cancel' : 'inlineRow accept'" @click="addOrRemoveRowFromExludes(wine.row, wine.csvWine)"></button></b-col>
              </b-row>
            </b-col>

          </b-row>
          <b-row v-if="notFoundWines.length > 0" class="mt-2">
            <b-col>
              <h4>
                Nicht gefundene Weine
              </h4>
            </b-col>
          </b-row>
          <b-row v-if="notFoundWines.length > 0" class="mt-2">
            <b-col>
              <b-table sticky-header striped hover :items="notFoundWines" :fields="notFoundListColumnsToShow">
              </b-table>
              <b-button size="sm" class="mb-3" @click="exportCSV()">Als CSV exportieren:</b-button>

            </b-col>
          </b-row>
          <b-row>
          <b-col>
            <h4>Datum der Aktualisierung setzen:</h4>
          <b-form-datepicker class="mt-3 mb-3" :locale="$i18n.locale" id="fromDatePicker" v-model="importDate"></b-form-datepicker>
            <ButtonFooter class="mt-3 mb-3" :backButtonVisible="true" :okButtonDisabled="false"
              :backButtonLabel="this.$t('general.buttons.back')" 
            :okButtonLabel="this.$t('updater.updateWines')" :okAction="() => { startRealUpdate()}" :backAction="handleBackFromStep3"/>
          </b-col>
        </b-row>
          </div>
        </b-col>

      </b-row>
      <b-row class="mt-3" v-if="currentStep === 4">
        <b-col>
          <h2>Update läuft</h2>
          aktuelle Zeile: {{ currentRow }} / {{ csvInput.length }}
        </b-col>
      </b-row>
    </div>

    <b-row v-if="csvError.length > 0">
      <b-col>

        <h4 class="alert-heading">Fehler beim Einlesen der CSV Datei</h4>
        <p>
          {{ csvError }}
        </p>

      </b-col>
    </b-row>
    

  </div>
</template>
<script>
import DataService from "@/services/StrapiService.js";
import TargetMapping from "@/components/updater/TargetMapping.vue";
import IdentifierMapping from "@/components/updater/IdentifierMapping.vue";
import { parsePotentiallyGroupedFloat } from "@/utils/functions.js";
import { addSupplierAndPrice, matchVenueWineWithEveryColumn, matchValues, deepValueOfObject, generateWineNameFromColumns, generateWineString } from "@/utils/venueWineFunctions.js";
import { read, utils } from 'xlsx';
import StepNavigation from "@/components/modals/addWine/StepNavigation.vue";
import ButtonFooter from "@/components/modals/ButtonFooter.vue";

export default {
  name: "UpdateAmounts",
  components: {
    TargetMapping,
    IdentifierMapping,
    StepNavigation,
    ButtonFooter
  },
  data() {
    return {
      currentStep: 0,
      showAlert: false,
      stepTitles: [this.$t('updater.step0'), this.$t('updater.step1'), this.$t('updater.step2'), this.$t('updater.step3')],
      wineSupplierNotFound: [],
      csvColumns: [],
      offlineWineSupplierList: [],
      csvInput: [],
      csvError: [],
      rowMappingObject: {},
      deleteWine: false,
      useAsDifference: false,
      identifierMappingObject: {},
      identifierToVenueWineKey: {
        'Artikelnummer': 'attributes.articleNumber',
        'Weingut': 'attributes.wine.data.attributes.winery.data.attributes.title',
        'Weinname': 'attributes.title',
        'Jahrgang': 'attributes.year',
        'Region': 'attributes.wine.data.attributes.region.data.attributes.title',
        'Land': 'attributes.wine.data.attributes.land.data.attributes.title',
        'Farbe': 'attributes.wine.data.attributes.wineType.data.attributes.title',
        'Rebsorte': 'attributes.wine.data.attributes.grapeVariety.data.attributes.title',
        'Flaschengröße': 'attributes.bottleSize.data.attributes.amount',
        'VK': 'attributes.sellingPrice',
      },
      fileName: '',
      selectedIdentifierOption: 'Standard',
      currentRow: 0,
      foundWinesCount: 0,
      matchingWines: [],
      notFoundWines: [],
      notFoundListColumnsToShow: [
        'Weingut',
        'Weinname',
        'Jahrgang',
        'Flaschengröße',
        'Grund'
      ],
      isImporting: false,
      importDate: new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())),
      dateDidChange: false,
      excludeRowsFromUpdate: [],
    }
  },
  created() {
  },
  methods: {
    handleBackFromStep1() {
      this.currentStep = this.currentStep - 1;
    },
    handleBackFromStep2() {
      this.currentStep = this.currentStep - 1;
    },
    handleBackFromStep3() {
      this.currentStep = this.currentStep - 1;
    },
    async readFile(event) {
      const file = event.target.files[0];
      this.fileName = file.name;

      if (file.name.endsWith('.xlsx')) {
        await this.readExcel(event, file);
        this.showAlert = true;
      } else if (file.name.endsWith('.csv')) {
        this.readCSV(event, file);
        this.showAlert = true;
      } else {
        console.log('Die Datei ist keine .xlsx oder .csv Datei');
        return;
      }
    },
    async readExcel(event, file) {
      const ab = await file.arrayBuffer();
      const wb = read(ab);
      const xlsxRows = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      const xlsxColumns = Object.keys(xlsxRows[0]);
      this.csvColumns = xlsxColumns
      this.csvInput = xlsxRows;
    },
    readCSV(event, file) {
      const self = this;
      var csvinput = [];
      var csverror = [];
      var count = 0;
      // this.offlineWineSupplier.map((field) => {if (this.wineSupplierNotFound.indexOf(field.attributes.title) == -1) { this.wineSupplierNotFound.push(field.attributes.title)}}) 
      this.$papa.parse(file, {
        //worker: true, // Don't bog down the main thread if its a big file
        header: true,
        download: true,
        skipEmptyLines: true,
        transformHeader: function (h) {
          return h.trim();
        },
        step: function (result) {
          // do stuff with result
          count = count + 1;
          if (result.errors.length == 0) {
            // clean up data
            if (result.data['Bezugsquelle'] != null && self.offlineWineSupplierList.indexOf(result.data['Bezugsquelle']) == -1 && self.wineSupplierNotFound.indexOf(result.data['Bezugsquelle']) == -1) {
              self.wineSupplierNotFound.push(result.data['Bezugsquelle']);
            }

            if (result.data['Flaschengröße'] != null && Number(result.data['Flaschengröße']) === 375 || result.data['Flaschengröße'] === '375') {
              result.data['Flaschengröße'] = 0.375;
            }
            if (result.data['EK'] != null) {
              result.data['EK'] = parsePotentiallyGroupedFloat(result.data['EK']);
            }
            if (result.data['VK'] != null) {
              result.data['VK'] = parsePotentiallyGroupedFloat(result.data['VK']);
            }
            csvinput.push(result.data);
          } else {
            csverror.push(result.errors[0]);
          }
        },
        complete: function () {
          self.csvError = csverror;
          self.csvInput = csvinput;
          self.csvColumns = Object.keys(csvinput[0]);
        },
      });
    },
    finishedTargetMapping(rowMappingObject, useAsDifference, deleteWine) {
      this.rowMappingObject = rowMappingObject;
      this.currentStep = this.currentStep + 1;
      this.useAsDifference = useAsDifference;
      this.deleteWine = deleteWine;
    },
    finishedIdentifierMapping(identifierMappingObject, selectedIdentifierOption) {
      this.identifierMappingObject = identifierMappingObject;
      this.selectedIdentifierOption = selectedIdentifierOption;
      this.updateVenueWines(true);
      this.currentStep = this.currentStep + 1;
    },
    findVenueWineFunction(offlineVenueWine, totalStringArray, identifierKeys, matchingIdentifier) {
      var isMatching = true;
        
        identifierKeys.forEach((identifierKey) => {
          var venueWineIdentifier = this.identifierToVenueWineKey[identifierKey];
          var valueA = deepValueOfObject(offlineVenueWine, venueWineIdentifier);
          var identfierDidMatch = false;
          for (var i = 0; i < totalStringArray.length; i++) {
            var valueB = totalStringArray[0];
            if (totalStringArray.length > 1 && i > 0) {
              const slicedArray = totalStringArray.slice(0, i);
              valueB = slicedArray.join(' ');
            }
          
            // add more parts of the string to the comparison
            if (matchValues(valueA, valueB)) {
              identfierDidMatch = true;
              if (i == 0) {
                totalStringArray = totalStringArray.slice(1);
              } else {
                totalStringArray = totalStringArray.slice(i);
              }
              this.removeIdentifierFromArray(identifierKey, matchingIdentifier)
              break
            }

          }

          isMatching = identfierDidMatch;
        })
        return isMatching;
    },
    async updateVenueWines(onlyTemporarily) {
      this.isImporting = true
      const venueWineResponse = await DataService.getVenueWines();
      this.offlineVenueWine = venueWineResponse["data"].data;
      if (onlyTemporarily) {
        this.notFoundWines = [];
        this.foundWinesCount = 0;
        this.matchingWines = [];
      }
      var venueWine;
      this.currentRow = 0;
      var identifierKeys = Object.keys(this.identifierMappingObject);
      var duplicateColumns = this.findDuplicateValuesInObject(this.identifierMappingObject);
   
        // we map all identfiers with one column
      var duplicateColumn = duplicateColumns[0];
       
        
      for await (const csvWine of this.csvInput) {
        this.currentRow = this.currentRow + 1;
        var matchingIdentifier= JSON.parse(JSON.stringify( identifierKeys));

        if (duplicateColumns.length > 0) {
        
          var totalString = csvWine[duplicateColumn];
          var totalStringArray = totalString.split(' ');
          // HIER WEITERMACHEN ERSTES Objekt FEHLER
          venueWine = this.offlineVenueWine.find((offlineVenueWine) => {

            return this.findVenueWineFunction(offlineVenueWine , totalStringArray, identifierKeys, matchingIdentifier)
           
            }
          );
        } else {
        
        
        
        venueWine = this.offlineVenueWine.find((offlineVenueWine) => {
          var isMatching = matchVenueWineWithEveryColumn(identifierKeys, offlineVenueWine, csvWine, this.identifierToVenueWineKey, this.identifierMappingObject, (identifierKey) => { this.removeIdentifierFromArray(identifierKey, matchingIdentifier) });
          return isMatching;
        });
              
        }
        var vineFromSheet = generateWineNameFromColumns(csvWine, this.identifierMappingObject);
        var withSearch = ''
        if (!venueWine) {
          var searchResult = await DataService.searchVenueWines(vineFromSheet, identifierKeys);
          if (searchResult.data && searchResult.data.length > 0) {
            var venueWineFromSearch = searchResult.data[0];
            if (venueWineFromSearch._score > 10) {
              venueWine = venueWineFromSearch;
              withSearch = '* (' + venueWineFromSearch._score + ')';
            }
          }
        }
        if (venueWine) {
          if (!onlyTemporarily && this.excludeRowsFromUpdate.indexOf(this.currentRow) == -1) {
            var venueWineObject = this.constructVenueWineObjectFromSelectedFields(csvWine, venueWine);
            await DataService.updateVenueWine(venueWine.id, venueWineObject);
          } else {
            this.foundWinesCount = this.foundWinesCount + 1;
            if (duplicateColumns.length > 0) {
              csvWine['Weinname'] = totalString;
            }
            this.matchingWines.push({
              vineFromSheet: vineFromSheet,
              vineFromVenue: generateWineString(venueWine) + ' ' + withSearch,
              row: this.currentRow,
              fromSearch: withSearch !== '',
              csvWine: csvWine
            });
          }
        } else {
          if (!onlyTemporarily) {
            continue;
          }
          csvWine['Grund'] = matchingIdentifier ? matchingIdentifier[0] : 'Kein Grund gefunden';
          csvWine['Zeile'] = this.currentRow + 1;
          if (duplicateColumns.length > 0) {
            csvWine['Weinname'] = totalString;
          }
          this.notFoundWines.push(csvWine)
        }
      }
        
     
      this.isImporting = false
    },
    removeIdentifierFromArray(word, array) {
      var index = array.indexOf(word);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    findDuplicateValuesInObject(obj) {
      var seen = {};
      var dupes = [];
      var keys = Object.keys(obj);
      keys.forEach(element => {
        var value = obj[element];
        if (seen[value]) {
          dupes.push(value);
        } else {
          seen[value] = true;
        }
      });
      return dupes;
    },
    constructVenueWineObjectFromSelectedFields(csvRow, storedVenueWine) {

      var venueWineAttributesMappingObject = {
        'Anzahl': 'amount',
        'VK': 'sellingPrice',
        'Regal': 'shelfNumber',
        'Artikelnummer': 'articleNumber',
        'Kommentar': 'comments',
        'Kassennotiz': 'cashNote',
        'Bezugsquelle': 'purchasePrices',
      };
      var venueWineObject = {
        attributes:
        {
          noRevenue: true,
          causeOfReduction: 'inventory'
        }
      };
      if (this.dateDidChange) {
        venueWineObject.attributes.newCreateDate = this.importDate;
      }
      if (this.deleteWine) {
        venueWineObject.attributes.deleted = true;
        return venueWineObject;
      }
      var selectedFieldsToChange = Object.keys(this.rowMappingObject);
      selectedFieldsToChange.forEach((field) => {
        var venueWineAtrribute = venueWineAttributesMappingObject[field];
        var value = csvRow[this.rowMappingObject[field]] ;
        if (venueWineAtrribute == 'purchasePrices') {

          const wineSupplier = this.offlineWineSupplier.find((wineSupplier) => {
            return wineSupplier.attributes.title.toLowerCase() == csvRow[field].toLowerCase();
          });
          if (!wineSupplier) {
            csvRow['Grund'] = 'Keinen vorhandenden Lieferant gefunden';
            this.notFoundWines.push(csvRow)
            return;
          }
          var venueWine = addSupplierAndPrice(storedVenueWine, csvRow['EK'], wineSupplier)
          venueWineObject['attributes'][venueWineAtrribute] = venueWine['attributes'][venueWineAtrribute]
        } else {
          if (this.useAsDifference && !isNaN(Number(value)) && !isNaN(Number(venueWineObject['attributes'][venueWineAtrribute]))) {
            venueWineObject['attributes'][venueWineAtrribute] = Number(venueWineObject['attributes'][venueWineAtrribute]) + Number(value);
          } else {
            venueWineObject['attributes'][venueWineAtrribute] = value;
          }

        }

      });
      return venueWineObject;
    },
    exportCSV() {
      var csv = this.$papa.unparse(this.notFoundWines);
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "notFoundWines.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    startRealUpdate() {
      this.updateVenueWines(false);
      this.currentStep = 4;
    },
    addOrRemoveRowFromExludes(row, wineFromSheet) {
      var index = this.excludeRowsFromUpdate.indexOf(row);
      if (index > -1) {
        // TODO remove from notFoundWines
        this.excludeRowsFromUpdate.splice(index, 1);
        this.notFoundWines = this.notFoundWines.filter((wine) => {
          return wine['Weinname'] !== wineFromSheet['Weinname'] && wine['Grund'] !== 'Manuell ausgeschlossen';
        });
      } else {
        wineFromSheet['Grund'] = 'Manuell ausgeschlossen';
        this.notFoundWines.push(wineFromSheet)
        this.excludeRowsFromUpdate.push(row);
      }
    }
  },
  watch: {
    importDate: function () {
      this.dateDidChange = true;
    }
  },
};
</script>
<style scoped>
.b-table-sticky-header {
  max-height: 250px !important;
}
</style>